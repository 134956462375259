<template lang="pug">
    header#header(:class="{ open }")
        router-link(:to="{ name: 'home' }", tag="h1") Euro Import - Land Rover
            img(src="@images/logos/logo-euroimport-landrover.png", alt="Euro Import - Land Rover")
        nav.menu
            ul.items
                li(@click="toggleMenu(false)").item
                    router-link(:to="{ name: 'home', hash: '#experience' }", tag="li") Experience
                //- li(@click="toggleMenu(false)").item
                    router-link(:to="{ name: 'home', hash: '#inscreva-se' }", tag="li") Inscreva-se
                li(@click="toggleMenu(false)").item
                    router-link(:to="{ name: 'home', hash: '#galeria' }", tag="li") Galeria
            button(@click="toggleMenu()")
                img(src="@images/icones/icon-menu.png", alt="Menu")
</template>

<script>
export default {
    name: "component-header",
    data() {
        return {
            open: false,
        }
    },
    methods: {
        toggleMenu(action = null) {
            if (action != null)
                this.open = action
            else
                this.open = !this.open
        },
    },
}
</script>

<style lang="stylus" scoped src="./Header.styl"></style>